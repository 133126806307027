import * as React from "react";
import { StoryblokEditor } from "@eimerreis/lauterbad-components";
import config from "../../gatsby-config.js";

export class Editor extends React.Component {
  render() {
    const sbConfigs = config.plugins.filter((item) => {
      return item.resolve === 'gatsby-source-storyblok'
    })
    const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}
  
    return (
      <StoryblokEditor config={sbConfig.options} />
    )
  };
}

export default Editor;